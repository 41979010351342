import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Items } from "../Components/Data";
import axios from 'axios'
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import 'react-phone-input-2/lib/style.css'
import { Print } from '@mui/icons-material';
import url from '../utils/url';
import CartItem from '../Components/CartItem';

const CheckOut = ({ onOrderConfirmed, para, tableno, classNam }) => {
  const api = url()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [addedData, setAddedData] = useState([])
  const [showMessage, setShowMessage] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);

  const itemsPerPage = 6;
  const paginatedItems = Items.slice(0, itemsPerPage);
  const history = useNavigate();

  const handleCloseMessage = () => {
    setShowMessage(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowMessage(true);
    if (!name || !email) {
      toast.error("Please fill in all required fields");
      return;
    }
    const orderDetails = {
      name,
      email,
    };
    // additional validation logic here
    if (name.length < 3) {
      toast.error("Name must be at least 3 characters long");
      return;
    }
    // end of additional validation logic
    const formdata = new FormData()
    formdata.append('name', name)
    formdata.append('email', email)

    try {
      await api.post(`/qrcheckout/${tableno}`, formdata);
      history(`/${tableno}/${para}/summary`)
      toast.success('🦄 Order Placed Successfully!!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        })
      onOrderConfirmed(orderDetails);
    } catch (error) {
      toast.error("Error placing order. Please try again later.");
    }
  }

  let getaddedData = async () => {
    let response = await api.get(`/qrcheckout/${tableno}`)
    if (response.status === 200) {
      setAddedData(response.data)
    }
  }
  const total = addedData.reduce((acc, item) => {
    return acc + item.quantity * item.price;
  }, 0);

  const [product,setProduct] = useState()
  const [isMainData, setMainData] = useState([])
  let getProduct = async () => {
    let response = await api.get(`/store/product/${para}`)
    if (response.status === 200 ) {
      setProduct(response.data)
      setMainData(response.data)
    }
  }




  useEffect(() => {
    getaddedData();
    getProduct()
  }, []);

  const handlePrintTable = () => {
    const table = document.querySelector('.table-container table');
    const tableHtml = table.outerHTML;
    const currentDate = new Date().toLocaleDateString();
    const newWindow = window.open('', '', 'height=500,width=800');
    newWindow.document.write('<html><head><title>Invoice</title>');
    newWindow.document.write(
      '<link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">'
    );
    newWindow.document.write(
      '<style>table{border-collapse:collapse;width:100%;}table td,table th{border-bottom: 1px solid #f2f2f2;font-family:Roboto,sans-serif;padding:8px;text-align:left;}table th{background-color:#F5F5F5;font-weight:bold;}</style>'
    );
    newWindow.document.write('</head><body>');
    newWindow.document.write(`<h2>AceTro</h2>`);
    newWindow.document.write(`<p>Customer Name: ${name}</p>`);
    newWindow.document.write(`<p>Email Address: ${email}</p>`);
    newWindow.document.write(`<p>Date: ${currentDate}</p>`);
    newWindow.document.write(`<p>Invoice Number: #002</p>`);
    newWindow.document.write(tableHtml);
    newWindow.document.write('</body></html>');
    newWindow.print();
  };


  


  return (
    <>
    <div className='mainContainer'>
      <div className='order-checkout'>
        <div className="checkout-container">
          <div className='preview-print'>
            <h3 className='category-headers'>Preview Your Order </h3>
            <i className='print-button' onClick={handlePrintTable}><Print /></i>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Qty</th>
                  <th>Details</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {addedData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.product_name}</td>
                    <td>{item.quantity}</td>
                    <td>{item.description}</td>
                    <td>Rs. {item.price}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr style={{'backgroundColor' : '#f2f2f2'}}>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>Rs. {total}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className='checkout-container'>
          <h2 className='category-headers'>Confirm Your Order  </h2>
          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              <label htmlFor='name'>Name</label>
              <input type='text' id='name' value={name} onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input type='email' id='email' value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>

            <button type='submit' >
              Place Order
            </button>
          </form>
          {showMessage && (
            <div className='alert-box'>
              <p>Thank you for your order, {name}!</p>
              <p className="close-btn" onClick={handleCloseMessage}>&times;</p>
            </div>
          )}
        </div>
      </div>
    </div>


      <div className={classNam}>

          {!addedData ? (
            <div className="addSomeItem">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/food-delivery-37c59.appspot.com/o/Images%2FemptyCart.png?alt=media&token=50b733d4-cdd9-4025-bffe-8efa4066ca24"
                alt=""
                className="emptyCart"
              />
            </div>
          ) : (
            <div className="cartCheckOutContianer">
              <div className="cartContainer">
                <h3>Items In Cart</h3>
                <div className="cartItems">
                  {addedData &&
                    addedData?.map((data) => (
                      <CartItem
                        key={data.id}
                        itemId={data.id}
                        name={data.product_name}
                        quantity={data.quantity}
                        imgSrc={product?.filter(element => element.id == data.product)[0].image}
                        qty={"4"}
                        price={data.price}
                        getdata={()=>getaddedData()}
                        para={para}
                      />
                    ))}
                </div>
              </div>
              <div className="totalSection">
                <h3>Total</h3>
                <p>
                  <span>Rs </span> {total}
                </p>
              </div>
            </div>
          )}
        </div>

    </>
  );
}

export default CheckOut;