import { AddRounded, Favorite, StarRounded } from "@mui/icons-material";
import React, { useState } from "react";

import { useEffect } from "react";
import axios from "axios";
import url from "../utils/url";
import { useParams } from "react-router-dom";
let cartData = [];

function ItemCard({ itemId, imgSrc, name, price, getdata,uniqueItems,exclusive,discount,para,tabelno }) {
    const {tableno} = useParams()
    const [myArray, setMyArray] = useState([])
    const api = url()
    const [checkoutData,setCheckoutData] = useState()
    let getaddedData = async () => {
      let response = await api.get(`/qrcheckout/${tableno}`)
      setCheckoutData(response.data)
    }
    useEffect(() => {
      getaddedData()
    },[uniqueItems])

    const dataAdd = async (itemId,price) => {
      const newArray = [...myArray];
      newArray[itemId] = true
      setMyArray(newArray);
      
      let found = checkoutData?.some((data) => data.product === itemId);
         if(!found) {
          const formdata = new FormData()
        formdata.append('product',itemId)
        formdata.append('price',price)
        formdata.append('quantity','1')
        formdata.append('product_name',name)
        const button = document.querySelector(".add-to-cart");
        button.disabled = true;
        await api.post(`/takeqrorder/${tableno}/${para}`, formdata).then(() => {
          getaddedData()
          getdata()
          setTimeout(() => {
              button.disabled = false;
            },1000)
        })
         }
        
        
    }

  return (
   
    <div className="card">
    <div className="card-image">
      <img src={imgSrc} alt={name} />
    </div>
    <div className="card-info">
      <h2>{name}</h2>
      <span className="discounted-price" >Rs. {exclusive ? (price - price*discount/100) : price }</span>
    </div>
    <div className="card-action">
       <button className="add-to-cart" 
              onClick={() => dataAdd(itemId,exclusive ? (price - price*discount/100) : price )}
        >
          {checkoutData?.some((data) => data.product === itemId) ? "Added" : "Add"}
    </button>
       </div>
  </div>
  );
}

export default ItemCard;
