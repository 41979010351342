import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import url from "../utils/url";

function Summary({para, tableno}) {
  const baseURL = process.env.REACT_APP_WEBSITE_NAME
  const api = url()
  const navigate = useNavigate()
  const [showMessage, setShowMessage] = useState(false);
  const [review, setReview] = useState({
    foodRating: 0,
    waiterRating: 0
  });

  const handleFoodRatingChange = (value) => {
    setReview(prevReview => ({
      ...prevReview,
      foodRating: value
    }));
  };

  const handleWaiterRatingChange = (value) => {
    setReview(prevReview => ({
      ...prevReview,
      waiterRating: value
    }));
  };

  const handleSubmit = async (event) => {
    setShowMessage(true);
    event.preventDefault();
    const formdata = new FormData()
    formdata.append('review_for_food',review.foodRating)
    formdata.append('review_for_service',review.waiterRating)
    await api.put(`/givereview/${tableno}`, formdata).then(() => {
      navigate(`/${tableno}/${para}`)
    })
    // Perform any additional logic, such as sending the review to an API
    setReview({
      foodRating: 0,
      waiterRating: 0
    })
  }

  return (
    <div className="order-checkout">
    <div className="checkout-container">
    <form onSubmit={handleSubmit} className="review-form">
      <h1 className='checkout-heading'>Review Form</h1>
      <div className="form-group">
        <label>How would you rate the quality of the food you received?</label>
        <StarRating
          name="foodRating"
          value={review.foodRating}
          onChange={handleFoodRatingChange}
          required
        />
      </div>
      <div className="form-group">
        <label>How would you rate the service you received from our waiter/waitress?</label>
        <StarRating
          name="waiterRating"
          value={review.waiterRating}
          onChange={handleWaiterRatingChange}
          required
        />
      </div>
      <button type="submit">Submit Review</button>
    </form>
    {showMessage && (
        <div className='alert-box'>
          <p>Thank you for your feedback!</p>

        </div>
      )}
    </div>
    </div>
  );
}

function StarRating(props) {
  const { name, value, onChange, required } = props;

  const handleClick = (event) => {
    const ratingValue = event.target.dataset.value;
    onChange(Number(ratingValue));
  };

  const handleMouseEnter = (event) => {
    const ratingValue = event.target.dataset.value;
    const starElements = event.target.parentNode.childNodes;
    starElements.forEach((starElement) => {
      if (starElement.dataset.value <= ratingValue) {
        starElement.classList.add("star-active");
      } else {
        starElement.classList.remove("star-active");
      }
    });
  };

  const handleMouseLeave = (event) => {
    const starElements = event.target.parentNode.childNodes;
    starElements.forEach((starElement) => {
      if (starElement.dataset.value <= value) {
        starElement.classList.add("star-active");
      } else {
        starElement.classList.remove("star-active");
      }
      });
      };
      
      return (
      <div className="star-rating" role="radiogroup" aria-label={name} required={required}>
      {[1, 2, 3, 4, 5].map((ratingValue) => (
      <span
      key={ratingValue}
      className={`star ${ratingValue <= value ? "star-active" : ""}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-value={ratingValue}
      role="radio"
      aria-checked={ratingValue === value}
      tabIndex="0"
      >
      ★
      </span>
      ))}
      </div>
      );
      }
      
      export default Summary;