import "./App.css";
import { Routes, Route, useParams, useNavigate } from 'react-router-dom'
import Home from "./pages/Home"
import Offer from './pages/Offer'
import Special from "./pages/Special";
import ButtomNav from "./Components/ButtomNav";
import Category from "./pages/Category";
import Product from "./pages/Product";
import { SearchRounded, ShoppingCartRounded } from "@mui/icons-material";
import { useEffect, useState } from "react";
import url from "./utils/url";
import CheckOut from "./pages/CheckOut";
import Summary from "./pages/Summary";


function App() {
  const params = useParams()
  const [trueId, setTrueId] = useState(true)
  const api = url()
  const [search, setSearch] = useState('')
  const [classNam, setClassNam] = useState("rightMenu")


  const repetitiveIds = {};
  const [uniqueItems, setUniqueItems] = useState()
  const [addedData, setAddedData] = useState([])

  const [table, setTable] = useState()
  const getTable = async () => {
    await api.get(`/store/tableno/${params.id}`).then((response) => {
      setTable(response.data)
      setTrueId(response.data.some(dat => dat.id == params.tableno))
    })
  }

  let getaddedData = async () => {
    await api.get(`/qrhome/${params.tableno}/${params.id}`)
    let response = await api.get(`/qrcheckout/${params.tableno}`)
    if (response.status === 200) {
      setAddedData(response.data)
      setUniqueItems(response.data.filter((item) => {
        if (repetitiveIds[item.product]) {
          return false;
        } else {
          repetitiveIds[item.product] = true;
          return true;
        }
      }))
    }
  }

  const navigate = useNavigate()
  let initiateOrder = async () => {
    await api.get(`/qrhome/${params.tableno}/${params.id}`).then((res) => {
      res.data.order.confirmed && navigate(`/${params.tableno}/${params.id}/summary`)
    })
  }

  useEffect(() => {
    getaddedData()
  }, [classNam])

  useEffect(() => {
    getTable()
    initiateOrder()
  }, [])


  const MenuClick = () => {
    if (classNam === "rightMenu active") {
      setClassNam("rightMenu")
    }
    else {
      setClassNam("rightMenu active")
    }
  }

  return (
    <div className='App'>

      <header>
        <div className="inputBox">
          <SearchRounded className="searchIcon" />
          <input type="text" placeholder="Search....." onChange={(e) => setSearch(e.target.value.toUpperCase())} />
        </div>
        <div className="toggleMenu" onClick={MenuClick} >
          <ShoppingCartRounded className="toggleIcon" />
        </div>
      </header>

      {trueId ?
        <>
          <Routes>
            <Route path='/' element={<Home search={search} classNam={classNam} setClassNam={setClassNam} para={params.id} tableno={params.tableno} />} />
            <Route path='/offer' element={<Offer uniqueItems={uniqueItems} para={params.id} tableno={params.tableno} classNam={classNam} getdata={() => getaddedData()} />} />
            <Route path='/special' element={<Special uniqueItems={uniqueItems} para={params.id} tableno={params.tableno} classNam={classNam} getdata={() => getaddedData()} />} />
            <Route path='/category' element={<Category classNam={classNam} para={params.id} />} />
            <Route path='/products/:id' element={<Product classNam={classNam} para={params.id} />} />
            <Route path='/checkout' element={<CheckOut para={params.id} classNam={classNam} tableno={params.tableno} />} />
            <Route path="/summary" element={<Summary para={params.id} tableno={params.tableno} />} />
          </Routes>
          <ButtomNav para={params.id} tableno={params.tableno} setClassNam={setClassNam} />
        </>
        :
        "Please submit the true Id"
      }

    </div>
  );
}

export default App;
