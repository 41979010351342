import React,{ useEffect, useState} from 'react'
import MenuContainer from './MenuContainer'
import {
    Favorite,
    FlashOn,
    HomeRounded,
    Settings,
  } from "@mui/icons-material"
import { useStateValue } from './StateProvider'
import { Items } from './Data'

const ButtomNav = ({para,tableno,setClassNam}) => {
    const [isMainData, setMainData] = useState(
        Items.filter((element) => element.itemId == "buger01")
      );
    
      const [{ cart, total }, dispatch] = useStateValue();
      const [totalPrice, setTotalPrice] = useState(0);
    
      // useEffect(() => {
      //   const menuLi = document.querySelectorAll("#menu li");
    
      //   function setMenuActive() {
      //     menuLi.forEach((n) => n.classList.remove("active"));
      //     this.classList.add("active");
      //   }
    
      //   menuLi.forEach((n) => n.addEventListener("click", setMenuActive));
      //   const menuCard = document
      //     .querySelector(".rowContainer")
      //     .querySelectorAll(".rowMenuCard");
    
      //   function setMenuCardActive() {
      //     menuCard.forEach((n) => n.classList.remove("active"));
      //     this.classList.add("active");
      //   }
    
      //   menuCard.forEach((n) => n.addEventListener("click", setMenuCardActive));
      // }, [isMainData, cart, total, totalPrice]);
      
    
      const setData = (itemId) => {
        setMainData(Items.filter((element) => element.itemId == itemId));
      };
      // useEffect(() => {
      //   const toggleIcon = document.querySelector(".toggleMenu");
      //   toggleIcon?.addEventListener("click", () => {
      //     document.querySelector(".rightMenu").classList.toggle("active");
      //   });
      // }, []);

  return (
    <div className="leftMenu" onClick={() => setClassNam("rightMenu")}>
        <ul id="menu">
          {/* prettier-ignore */}
          <MenuContainer link= {`/${tableno}/${para}/offer`} icon = {<FlashOn />} />
          <MenuContainer link = {`/${tableno}/${para}`} icon = {<HomeRounded />}  isHome/>
          <MenuContainer link = {`/${tableno}/${para}/special`} icon = {<Favorite />}  />
          <div className="indicator"></div>
        </ul>
      </div>
  )
}

export default ButtomNav