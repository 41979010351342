import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useStateValue } from "../Components/StateProvider";
import { actionType } from "../Components/reducer";
import { AddRounded, Check } from "@mui/icons-material";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CartItem from "../Components/CartItem";
import { Link } from "react-router-dom";
import url from "../utils/url";


const Product = ({classNam,para}) => {
  const params = useParams()
  const baseURL = process.env.REACT_APP_WEBSITE_NAME
  const api = url()
  const [productData, setProductData] = useState();
  const [products,setProducts] = useState()
  const [myArray, setMyArray] = useState(false);
  const [dispatch] = useStateValue();
  const [checkoutData,setCheckoutData] = useState([]);
  const [addedToCart, setAddedToCart] = useState({}); // updated state variable to store added products

  const repetitiveIds = {};
  const [uniqueItems,setUniqueItems] = useState()
  const [addedData, setAddedData] = useState([])

  const totalPrice = uniqueItems?.reduce((acc, item) => {
    return acc + item.quantity * item.price;
  }, 0);

  let getAddedData = async () => {
    await api.get(`/qrhome/${para}`)
    let response = await api.get(`/qrcheckout/${para}`)
    if(response.status === 200){
      setCheckoutData(response.data);
      setAddedData(response.data)
      setUniqueItems(response.data.filter((item) => {
        if (repetitiveIds[item.product]) {
          return false;
        } else {
          repetitiveIds[item.product] = true;
          return true;
        }
      }))
    }
  }

  useEffect(() => {
    getAddedData();
  }, [myArray]);

  useEffect(() => {
    const fetchProductData = async () => {
      const response = await api.get(`/store/product`);
      if (response.status === 200) {
        setProducts(response.data)
        setProductData(response.data.filter((element) => element.category == params.id));
      }
    };
    fetchProductData();
  }, []);

  const calculateDiscountedPrice = (productPrice, discount) => {
    const discountedPrice = productPrice - (productPrice * discount) / 100;
    // return discountedPrice.toFixed(1);
    return discountedPrice
  };

  const handleAddToCart = async (product) => {
    const found = checkoutData.some((data) => data.product === product.id);
    if (!found) {
      const formData = new FormData();
      formData.append("product", product.id);
      formData.append("price", product.product_discount
          ? calculateDiscountedPrice(product.price, product.product_discount)
          : product.price);
      formData.append("quantity", "1");
      formData.append("product_name", product.name);
      await api.post(`/takeqrorder/${para}`, formData).then(() => {
        setMyArray(!myArray)
      })
    }

    setAddedToCart({ ...addedToCart, [product.id]: true }); // update state variable to store added product

    dispatch({
      type: actionType.ADD_TO_CART,
      item: {
        id: product.id,
        name: product.name,
        price: product.price,
        image: product.imgSrc,
      },
    });
  };

  return (
    <>
    <div className="category-products">
      <table>
        <tbody>
          {productData?.length > 0 && productData.map((product) => (
            <tr key={product.id}>
              <td><img src={baseURL + product.image}alt={product.name} /></td>
              <td> <h3 className="itemName">{product.name}</h3></td>
              <td>Rs. 
              {calculateDiscountedPrice(
                product.price,
                product.product_discount
              )}
              </td>
              <td>
                <i className='addToCart' onClick={() =>
              handleAddToCart({
                id: product.id,
                name: product.name,
                price: product.product_discount
                  ? calculateDiscountedPrice(
                      product.price,
                      product.product_discount
                    )
                  : product.price,
                image: product.imgSrc,
              })
            }>
                {checkoutData?.some((data) => data.product === product.id) ? <DoneRoundedIcon /> : <AddRounded />}
                </i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    <div>
    <div className={classNam}>

{!uniqueItems ? (
  <div className="addSomeItem">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/food-delivery-37c59.appspot.com/o/Images%2FemptyCart.png?alt=media&token=50b733d4-cdd9-4025-bffe-8efa4066ca24"
      alt=""
      className="emptyCart"
    />
  </div>
) : (
  <div className="cartCheckOutContianer">
    <div className="cartContainer">
      <h3>Items In Cart</h3>
      <div className="cartItems">
        {uniqueItems &&
          uniqueItems?.map((data) => (
            <CartItem
              key={data.id}
              itemId={data.id}
              name={data.product_name}
              quantity={data.quantity}
              imgSrc={products?.filter(element => element.id == data.product)[0].image}
              qty={"4"}
              price={data.price}
              getdata={()=>getAddedData()}
              para={para}
            />
          ))}
      </div>
    </div>
    <div className="totalSection">
      <h3>Total</h3>
      <p>
        <span>Rs </span> {totalPrice}
      </p>
    </div>
    
    <Link to={`/${para}/checkout`}> 
    <button type="submit">Order Summary</button>
    </Link>

  </div>
)}
</div>

</div>       
    </>
  );
};

export default Product;
