import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route} from "react-router-dom";
import { StateProvider } from "./Components/StateProvider";
import reducer, { initialState } from "./Components/reducer";
import Description from "./pages/Description";



ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <BrowserRouter>
        <Routes>
          <Route path='/:tableno/:id/*' element={<App />} />
          <Route path='/description/:quan/:ids/:para/:tableno' element={<Description />} />
        </Routes>
      </BrowserRouter>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
