import { AddRounded } from "@mui/icons-material";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import React, { useState, useEffect } from "react";
import axios from "axios";
import { actionType } from "../Components/reducer";
import { useStateValue } from "../Components/StateProvider";
import CartItem from "../Components/CartItem";
import { Link, useParams } from "react-router-dom";
import url from "../utils/url";


function Offer({ classNam, para, tableno }) {
  const [{ cart }, dispatch] = useStateValue();
  // const { tableno,para} = useParams()
  const [products, setProducts] = useState([]);
  const [allproducts, setAllProducts] = useState()
  const baseURL = process.env.REACT_APP_WEBSITE_NAME
  const api = url()
  const [myArray, setMyArray] = useState(false);
  const [checkoutData, setCheckoutData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/store/product/${para}`);
      setAllProducts(response.data)
      const data = response.data.filter((product) => product.exclusive === true);
      setProducts(data);
    };
    fetchData();
  }, []);

  const repetitiveIds = {};
  const [uniqueItems, setUniqueItems] = useState()
  const [addedData, setAddedData] = useState([])

  const totalPrice = uniqueItems?.reduce((acc, item) => {
    return acc + item.quantity * item.price;
  }, 0);



  let getAddedData = async () => {
    await api.get(`/qrhome/${tableno}/${para}`)
    let response = await api.get(`/qrcheckout/${tableno}`)
    if (response.status === 200) {
      setCheckoutData(response.data);
      setAddedData(response.data)
      setUniqueItems(response.data.filter((item) => {
        if (repetitiveIds[item.product]) {
          return false;
        } else {
          repetitiveIds[item.product] = true;
          return true;
        }
      }))
    }
  }

  useEffect(() => {
    getAddedData();
  }, [myArray]);

  const calculateDiscountedPrice = (productPrice, discount) => {
    const discountedPrice = productPrice - (productPrice * discount) / 100;
    // return discountedPrice.toFixed(1);
    return discountedPrice
  };

  const [clicked, setClicked] = useState(false)
  const handleAddToCart = async (product) => {
    setClicked(true)
    const found = checkoutData.some((data) => data.product === product.id);
    if (!found) {
      const formData = new FormData();
      formData.append("product", product.id);
      formData.append("price", product.product_discount
        ? calculateDiscountedPrice(product.price, product.product_discount)
        : product.price);
      formData.append("quantity", "1");
      formData.append("product_name", product.name);
      await api.post(`/takeqrorder/${tableno}/${para}`, formData).then(() => {
        setMyArray(!myArray)
      })
    }
  };


  return (
    <main>
      <div className="mainContainer">
        <h3 className="category-header"><span className="border-category"> Exclusive </span></h3>
        <div className="category-container">
          {products.map((product) => (
            <div className="itemCard" key={product.id}>
              <div className="imgBox">
                <img
                  src={baseURL + product.image}
                  alt=""
                  className="itemImg"
                />
              </div>
              <div className="itemContent">
                {product.product_discount && (
                  <div className="discount-overlay">
                    <span>{product.product_discount}% off</span>
                  </div>
                )}
                <h3 className="itemName">{product.name}</h3>
                <div className="bottom">
                  <div className="ratings">
                    <span className="previous-price">Rs.{product.price}</span>{" "}
                    <span className="discounted-price">
                      Rs.
                      {calculateDiscountedPrice(
                        product.price,
                        product.product_discount
                      )}
                    </span>
                  </div>
                  <i
                    className="addToCart"
                    onClick={() =>
                      handleAddToCart({
                        id: product.id,
                        name: product.name,
                        price: product.product_discount
                          ? calculateDiscountedPrice(
                            product.price,
                            product.product_discount
                          )
                          : product.price,
                        image: product.imgSrc,
                      })
                    }
                  >
                    {checkoutData?.some((data) => data.product === product.id) ? <DoneRoundedIcon /> : <AddRounded />}
                    {/* <AddRounded /> */}
                  </i>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={classNam}>

        {!uniqueItems ? (
          <div className="addSomeItem">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/food-delivery-37c59.appspot.com/o/Images%2FemptyCart.png?alt=media&token=50b733d4-cdd9-4025-bffe-8efa4066ca24"
              alt=""
              className="emptyCart"
            />
          </div>
        ) : (
          <div className="cartCheckOutContianer">
            <div className="cartContainer">
              <h3>Items In Cart</h3>
              <div className="cartItems">
                {uniqueItems &&
                  uniqueItems?.map((data) => (
                    <CartItem
                      key={data.id}
                      itemId={data.id}
                      name={data.product_name}
                      quantity={data.quantity}
                      imgSrc={allproducts?.filter(element => element.id == data.product)[0].image}
                      qty={"4"}
                      price={data.price}
                      getdata={() => getAddedData()}
                      para={para}
                    />
                  ))}
              </div>
            </div>
            <div className="totalSection">
              <h3>Total</h3>
              <p>
                <span>Rs </span> {totalPrice}
              </p>
            </div>

            <Link to={`/${tableno}/${para}/checkout`}>
              <button type="submit">Order Summary</button>
            </Link>

          </div>
        )}
      </div>

    </main>
  );
}

export default Offer;
