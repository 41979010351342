import { AddRounded, EditRounded, RemoveRounded } from "@mui/icons-material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import url from "../utils/url";

function CartItem({ itemId, name, imgSrc, price, quantity, getdata, para }) {
  const baseURL =process.env.REACT_APP_WEBSITE_NAME
  const {tableno} = useParams()
  const api = url()
  const [qty, setQty] = useState(quantity);
  const [itemPrice, setItemPrice] = useState(price);
  const [requestInProgress, setRequestInProgress] = useState(false); // New state flag


  useEffect(() => {
    setItemPrice(parseInt(qty) * parseFloat(price))
  }, [qty]);

  const updateQty = (action, id) => {
    const desp = localStorage.getItem(`description/${id}`)
    if(requestInProgress) return
    setRequestInProgress(true)
    
    if (action == "add") {
      const formdata = new FormData()
      formdata.append('quantity',qty + 1)
      formdata.append('description',desp)
      api.put(`/editorder/${id}`,formdata).then(() => {
        getdata()
        setQty(qty + 1)
      })
      .finally(() => setRequestInProgress(false))
    } else {
      if (qty == 1) {
        api.delete(`/editorder/${id}`).then(() => {
          localStorage.removeItem(`description/${id}`)
          getdata()
        })
        .finally(() => setRequestInProgress(false))
      } else {
        const formdata = new FormData()
      formdata.append('quantity',qty - 1)
      formdata.append('description',desp)
      api.put(`/editorder/${id}`,formdata).then(() => {
        getdata()
        setQty(qty - 1);
      })
      .finally(() => setRequestInProgress(false))
      }
    }
  };

  return (
    <div className="cartItem" id={itemId}>
      <div className="imgBox">
        <img src={baseURL + imgSrc} alt="" />
      </div>
      <div className="itemSection">
        <h3 className="itemName">{name}</h3>
        <div className="itemQuantity">
          <span>x {qty}</span>
          <div className="quantity">
            <Link to={`/description/${qty}/${itemId}/${para}/${tableno}`}> <EditRounded className="des-icon " /> </Link>
            <RemoveRounded
              className="itemRemove"
              onClick={() => updateQty("remove", itemId)}
            />
            <AddRounded
              className="itemAdd"
              onClick={() => updateQty("add", itemId)}
            />
          </div>
        </div>
      </div>
      <p className="itemPrice">
        <span className="dolorSign">Rs.</span>{" "}
        <span className="itemPriceValue">{itemPrice}</span>
      </p>
    </div>
  );
}

export default CartItem;