import React from 'react'
import { useEffect, useState } from "react"
import MenuCard from '../Components/MenuCard'
import ItemCard from '../Components/ItemCard'
import CartItem from '../Components/CartItem'
import { Link, useNavigate } from 'react-router-dom'
import img from "../assets/Alll.png"
import url from '../utils/url'

const Home = ({search,classNam,para,tableno,setClassNam}) => {
    const baseURL = process.env.REACT_APP_WEBSITE_NAME
    const api = url()
    const navigate = useNavigate()
    const [product,setProduct] = useState()
    const [categoryData, setCategoryData] = useState()
    const [isMainData, setMainData] = useState([])
    const [addedData, setAddedData] = useState([])
    const find = (data) => {
      return data.filter((item) => item.name.toUpperCase().includes(search))
    }

    let getNotes = async () => {
        let response = await api.get(`/store/category/${para}`)
        if (response.status === 200 ) {
          setCategoryData(response.data)
        }
    }

    let getProduct = async () => {
      let response = await api.get(`/store/product/${para}`)
      if (response.status === 200 ) {
        setProduct(response.data)
        setMainData(response.data)
      }
    }

    let initiateOrder = async () => {
      await api.get(`/qrhome/${tableno}/${para}`).then((res) => {
        res.data.order.confirmed && navigate(`/${tableno}/${para}/summary`)
      })
    }

    let getaddedData = async () => {
      let response = await api.get(`/qrcheckout/${tableno}`)
      if(response.status === 200){
        setAddedData(response.data)
        setUniqueItems(response.data.filter((item) => {
          if (repetitiveIds[item.product]) {
            return false;
          } else {
            repetitiveIds[item.product] = true;
            return true;
          }
        }))
      }
    }
    

  const repetitiveIds = {};
  const [uniqueItems,setUniqueItems] = useState()
    
      const totalPrice = addedData.reduce((acc, item) => {
        return acc + item.quantity * item.price;
      }, 0)
    

      const setData = (itemId) => {
        if(itemId == 0){
          setMainData(product)
          return
        }
        setMainData(product.filter((element) => element.category == itemId));
      };
      

      useEffect(() => {
        getNotes()
        getProduct()
        getaddedData()
        initiateOrder()
        
      }, [])



  return (
        <>
      
      <main>
        {search ? 
        <div className="mainContainer">
        <div className="dishContainer">
          <div className="dishItemContainer">
          {product &&
            find(product).map((data) => (
              <ItemCard
                key={data.id}
                itemId={data.id}
                imgSrc={baseURL + data.image}
                name={data.name}
                price={data.price}
                exclusive={data.exclusive}
                discount={data.product_discount}
                getdata={()=>getaddedData()}
                uniqueItems={uniqueItems}
                para={para}
                tableno={tableno}
              />
            ))}
        </div>
      </div>
    </div>
        : 
        <div className="mainContainer">

          <div className="dishContainer">

            <div className="rowContainer">
              <div onClick={() => setData("0")}>
                <MenuCard name='All' isActive imgSrc={img} />
              </div>
              {categoryData &&
                categoryData.map((data) => (
                  <div key={data.id} onClick={() => setData(data.id)}>
                    <MenuCard
                      imgSrc={data.image}
                      name={data.name}
                    />
                  </div>
                ))}
            </div>

            <div className="dishItemContainer">
              {isMainData &&
                isMainData?.map((data) => (
                  <ItemCard
                    key={data.id}
                    itemId={data.id}
                    imgSrc={baseURL + data.image}
                    name={data.name}
                    price={data.price}
                    exclusive={data.exclusive}
                    discount={data.product_discount}
                    getdata={()=>getaddedData()}
                    uniqueItems={uniqueItems}
                    para={para}
                    tableno={tableno}
                  />
                ))}
            </div>
          </div>
        </div>
        }


        <div className={classNam}>

          {!addedData ? (
            <div className="addSomeItem">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/food-delivery-37c59.appspot.com/o/Images%2FemptyCart.png?alt=media&token=50b733d4-cdd9-4025-bffe-8efa4066ca24"
                alt=""
                className="emptyCart"
              />
            </div>
          ) : (
            <div className="cartCheckOutContianer">
              <div className="cartContainer">
                <h3>Items In Cart</h3>
                <div className="cartItems">
                  {addedData &&
                    uniqueItems?.map((data) => (
                      <CartItem
                        key={data.id}
                        itemId={data.id}
                        name={data.product_name}
                        quantity={data.quantity}
                        imgSrc={product?.filter(element => element.id == data.product)[0].image}
                        qty={"4"}
                        price={data.price}
                        getdata={()=>getaddedData()}
                        para={para}
                      />
                    ))}
                </div>
              </div>
              <div className="totalSection">
                <h3>Total</h3>
                <p>
                  <span>Rs </span> {totalPrice}
                </p>
              </div>
               
              <Link to={`/${tableno}/${para}/checkout`}> 
              <button type="submit" onClick={() => setClassNam("rightMenu")}>Order Summary</button>
              </Link>

            </div>
          )}
        </div>


      </main>
      </>
  )
}

export default Home