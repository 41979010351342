import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import CartItem from "../Components/CartItem";
import url from "../utils/url";

const Category = ({classNam, para}) => {
  const baseURL = process.env.REACT_APP_WEBSITE_NAME
  const api = url()
  const [categoryData, setCategoryData] = useState([]);


  const [products, setProducts] = useState([]);
  const [allproducts, setAllProducts] = useState()
  const [myArray, setMyArray] = useState(false);
  const [checkoutData,setCheckoutData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get('/store/product');
      setAllProducts(response.data)
      const data = response.data.filter((product) => product.exclusive === true);
      setProducts(data);
    };
    fetchData();
  }, []);

  const repetitiveIds = {};
  const [uniqueItems,setUniqueItems] = useState()
  const [addedData, setAddedData] = useState([])

  const totalPrice = uniqueItems?.reduce((acc, item) => {
    return acc + item.quantity * item.price;
  }, 0);

  

  let getAddedData = async () => {
    await api.get(`/qrhome/${para}`)
    let response = await api.get(`/qrcheckout/${para}`)
    if(response.status === 200){
      setCheckoutData(response.data);
      setAddedData(response.data)
      setUniqueItems(response.data.filter((item) => {
        if (repetitiveIds[item.product]) {
          return false;
        } else {
          repetitiveIds[item.product] = true;
          return true;
        }
      }))
    }
  }

  useEffect(() => {
    getAddedData();
  }, [myArray]);

  useEffect(() => {
    const fetchCategoryData = async () => {
      const response = await api.get(`/store/category`);
      if (response.status === 200) {
        setCategoryData(response.data);
      }
    };
    fetchCategoryData();
  }, []);

  return (
    <div>
      <h3 className="category-header"><span className="border-category"> List of All Category</span></h3>
    <div className="category-container">
      {categoryData.map((category) => (
        <div key={category.id} className="category-card">
          <Link to={`/${para}/products/${category.id}`}>
            <img src={baseURL + category.image}  alt={category.name} />
            <h3>{category.name}</h3>
          </Link>
        </div>
      ))}
    </div>

    <div>
    <div className={classNam}>

{!uniqueItems ? (
  <div className="addSomeItem">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/food-delivery-37c59.appspot.com/o/Images%2FemptyCart.png?alt=media&token=50b733d4-cdd9-4025-bffe-8efa4066ca24"
      alt=""
      className="emptyCart"
    />
  </div>
) : (
  <div className="cartCheckOutContianer">
    <div className="cartContainer">
      <h3>Items In Cart</h3>
      <div className="cartItems">
        {uniqueItems &&
          uniqueItems?.map((data) => (
            <CartItem
              key={data.id}
              itemId={data.id}
              name={data.product_name}
              quantity={data.quantity}
              imgSrc={allproducts?.filter(element => element.id == data.product)[0].image}
              qty={"4"}
              price={data.price}
              getdata={()=>getAddedData()}
              para={para}
            />
          ))}
      </div>
    </div>
    <div className="totalSection">
      <h3>Total</h3>
      <p>
        <span>Rs </span> {totalPrice}
      </p>
    </div>
    
    <Link to={`/${para}/checkout`}> 
    <button type="submit">Order Summary</button>
    </Link>

  </div>
)}
</div>

</div>

    </div>
  );
};

export default Category;
